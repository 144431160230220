.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  margin: auto;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  /* margin-top: 1%; */
  margin: 10px 20px;
}

.footer a {
  color: #f8b400;
  text-decoration: none;
}

.footer .social-icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  margin-top: 10px;
}

.social-icons {
  width: 100px; /* Set a fixed width for the icons' container */
  margin: 0 auto; /* Center the container itself */
}

.footer p {
  font-family: 'Avenir Next Condensed';
  font-size: 24px;
}

.icon {
  margin: 0 10px;
}
