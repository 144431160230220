.menu-card {
  margin: auto;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  /* margin-top: 1%; */
  max-width: 100%;
  margin: 10px 20px;
  /* width: 90%; */
}

.menu-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Papyrus';
  font-weight: bolder;
}

.menu-button {
  color: rgb(8, 8, 8);
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: solid #f39c12 1.5px;
  /* background-color: #f4a62a; */
}

.menu-button:hover {
  background-color: #f4a62a;
  transform: translateY(-2px);
  color: white;
}

.menu-button.active {
  background-color: #f4a62a;
  color: white;
  transform: scale(1.05);
}

.wine-details {
  margin-top: 40px;
  /* border: solid red 2px; */
  margin: 0 60px;
}

.wine-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px dotted #ddd;
  font-size: 1rem; /* Base font size */
}

.wine-item .wine-name {
  font-size: 2.2rem;
  color: #f39c12;
  font-family: 'SignPainter';
}

.wine-item .wine-price {
  font-size: 1rem;
  color: #333;
}

.wine-item .wine-750ml {
  font-weight: bold;
}

.wine-item .wine-375ml  {
  font-style: italic;
  color: #888;
}

.wine-item .wine-330ml  {
  font-style: italic;
  color: #888;
}

/* Responsive Design for Mobile Devices */
@media (max-width: 768px) {
  .menu-card {
    padding: 10px;
  }

  .menu-buttons {
    flex-direction: column;
    gap: 5px; /* Reduce the gap for mobile */
  }

  .menu-button {
    width: 100%; /* Full width buttons on mobile */
    padding: 10px;
    font-size: 1rem; /* Adjust font size */
  }

  .wine-item {
    flex-direction: column; /* Stack wine details vertically */
    text-align: center;
    padding: 10px 0;
  }

  .wine-item .wine-name {
    font-size: 1.5rem; /* Smaller font size on mobile */
  }

  .wine-item .wine-price {
    font-size: 1rem;
  }
}

/* Responsive Design for Tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* .menu-card {
    padding: 20px;
    width: 80%; 
  } */

  .menu-buttons {
    gap: 10px; /* Keep the button gap smaller for tablets */
  }

  .menu-button {
    font-size: 1.2rem; /* Slightly larger font size for tablets */
    padding: 12px 20px;
  }

  .wine-item .wine-name {
    font-size: 1.8rem; /* Medium font size on tablets */
  }

  .wine-item .wine-price {
    font-size: 1.1rem;
  }
}

/* Larger screens */
@media (min-width: 1025px) {
  /* .menu-card {
    max-width: 800px; 
  } */

  .wine-item {
    font-size: 1.2rem;
  }

  .wine-item .wine-name {
    font-size: 2rem;
  }

  .wine-item .wine-price {
    font-size: 1.2rem;
  }
}
