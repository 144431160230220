.app {
  margin: 0 auto; /* Center the app */
  /* padding: 0px;  */
  box-shadow: 0 2px 10px rgba(233, 27, 27, 0.1); /* Shadow for depth */
  background-color: #f5f5f5; /* Light background color */
  /* max-width: 2000px;  */
  
  width: 100%; /* Full width to make it responsive */
}

@media (max-width: 768px) {
  .app {
    padding: 10px; /* Reduce padding on smaller screens */
    /* box-shadow: none;  */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .app {
    max-width: 900px; /* Slightly narrower on tablet-sized screens */
  }
}
