.excel-uploader-container {
  background-color: #f4f4f4; /* Light grey background */
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  margin: 50px auto; /* Center the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow */
}

.upload-title {
  font-family: 'Roboto', sans-serif; /* Use a modern font */
  font-size: 24px;
  color: #333; /* Dark grey text */
  text-align: center;
  margin-bottom: 20px;
}

.file-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  transition: border 0.3s ease;
}

.file-input:hover,
.file-input:focus {
  border-color: #007bff; /* Blue border on hover */
  outline: none;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Spread buttons out */
  gap: 10px; /* Space between buttons */
}

.done-button {
  flex: 1; /* Make the button take up available space */
  background-color: #007bff; /* Primary blue */
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.done-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.clear-button {
  flex: 1;
  background-color: #dc3545; /* Red for clear button */
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

#storeSelect {
  width: 100%;
  padding: 10px;
  margin: 15px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

label {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column; /* Stack buttons on small screens */
  }

  .done-button,
  .clear-button {
    margin-bottom: 10px; /* Add space between stacked buttons */
  }
}
