@font-face {
  font-family: 'City Mango';
  src: url('../fontfamily/hanscostudio.com.ttf');
  font-weight: 400;
}

/* General Header Styles */
.header {
  display: flex;
  flex-direction: column; /* Stacks elements vertically */
  margin: 20px;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.logo-text-container {
  display: flex;
  flex-direction: column; /* Defaults to vertical layout */
  align-items: center;
  margin: 8px 0;
  text-align: center;
}

.text-effect-container {
  display: flex;
  flex-direction: column;
 
  font-size: 14px;
}

.header img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.flip-animation {
  animation: flip 5s infinite;
  transition: transform 0.2s ease-in-out;
}

/* Buttons */
.outlates-button,
.login-button-home {
  padding: 12px;
  background-color: #f8b400;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
  width: 100%; /* Full width for smaller screens */
}

.outlates-button:hover,
.login-button-home:hover {
  background-color: #e09000;
}

.headerbuttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Space between buttons */
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Responsive Styles */
/* Mobile (default styles for smaller screens) */
@media (max-width: 768px) {
  .logo-text-container {
    flex-direction: column; /* Stack logo and text vertically */
  }

  .text-effect-container TextGenerateEffectDemo  {
    font-size: 4px; 
  }

  .flip-animation {
    height: 150px;
    width: 150px;
  }

  .outlates-button,
  .login-button-home {
    font-size: 14px;
    padding: 10px;
    margin: 5px;
    width: 100%; /* Ensure buttons take full width */
  }

  .header > div {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Keeps buttons aligned */
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1024px) {
  .logo-text-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text-effect-container {
    margin-left: 20px;
  }

  .flip-animation {
    height: 180px;
    width: 180px;
  }

  .outlates-button,
  .login-button-home {
    font-size: 15px;
    padding: 12px;
    margin: 15px;
    width: auto; /* Buttons return to original size on larger screens */
  }

  .header > div {
    width: auto;
    justify-content: flex-start; /* Keep buttons in original position */
  }
}

/* Desktop */
@media (min-width: 1025px) {
  .header {
    flex-direction: column;
    justify-content: space-between;
  }

  .logo-text-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text-effect-container {
    margin-left: 20px;
  }

  .flip-animation {
    height: 200px;
    width: 200px;
  }

  .outlates-button,
  .login-button-home {
    font-size: 16px;
    padding: 12px 20px;
    width: auto; /* Default width for desktop */
  }

  .header > div {
    width: auto;
    display: flex;
    justify-content: space-between; /* Keep buttons aligned horizontally */
  }
}
