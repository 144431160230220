/* LoginScreen.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 46px 0;
  animation: fadeIn 0.5s ease-in-out;
  background-color: #49a133;
  background: linear-gradient(to bottom, red, green);
  width: 100%;
  min-height: 100vh; /* Ensure the container takes full viewport height */
  box-sizing: border-box; /* Include padding in width and height */
}

.login-container {
  width: 90%; /* Default width for smaller screens */
  max-width: 350px; /* Prevent the container from exceeding this width */
  padding: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.8s ease-in-out;
  text-align: center;
}

.logo {
  width: 100%; /* Adjust for responsiveness */
  max-width: 300px; /* Prevent it from growing too large */
  height: auto;
  display: block;
  margin: 0 auto 20px;
  border-radius: 10px;
}

.login-title {
  color: #6a11cb;
  font-size: 24px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input {
  padding: 12px;
  margin-bottom: 15px;
  border: 2px solid #f8b400;
  border-radius: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input:focus {
  border-color: #f8b400;
  outline: none;
  box-shadow: 0 0 5px rgba(37, 117, 252, 0.5);
}

.password-container {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: #6a11cb;
  transition: color 0.3s;
}

.toggle-password:hover {
  color: #f8b400;
}

.login-button {
  background-color: #2575fc;
  color: white;
  border: none;
  font-size: 14px; /* Slightly larger font for better readability */
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  align-self: center;
  padding: 10px 26px;
}

.login-button:hover {
  background-color: #f8b400;
  transform: scale(1.05);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    padding: 15px; /* Reduce padding on smaller devices */
    width: 95%; /* Use almost full width of the screen */
  }

  .login-title {
    font-size: 20px; /* Slightly smaller title for smaller screens */
  }

  .login-button {
    font-size: 12px; /* Reduce button font size for smaller screens */
    padding: 8px 20px; /* Adjust padding for smaller buttons */
  }
}

@media (max-width: 480px) {
  .login-title {
    font-size: 18px; /* Further reduce title size for very small screens */
  }

  .login-button {
    font-size: 11px; /* Reduce button font size further */
    padding: 6px 16px; /* Compact buttons for very small screens */
  }

  .toggle-password {
    right: 8px; /* Adjust toggle position for smaller screens */
    top: 10px;
  }
}
